<template>
  <div class="orderRecord">
    <el-table :data="tableData" ref='table' stripe border :max-height="maxTableHeight">
      <el-table-column prop="orderNumber" label="订单号" show-overflow-tooltip min-width="150"> </el-table-column>

      <el-table-column prop="mealName" label="套餐名称"> </el-table-column>

      <el-table-column prop="mealTypeStr" label="套餐类型"> </el-table-column>

      <el-table-column prop="type" label="订单类型">
          <template slot-scope="scope">
              <span v-if="scope.row.type === 1">微信订单</span>
              <span v-else-if="scope.row.type === 2">后台订单</span>
              <span v-else-if="scope.row.type === 3">代理商订单</span>
          </template>
      </el-table-column>

      <el-table-column prop="flowSize" label="套餐流量"> </el-table-column>

      <el-table-column prop="effectType" label="次月生效">
          <template slot-scope="scope">
            <span v-if="scope.row.mealType==2&&(scope.row.mealType==10||scope.row.mealType==12)">是</span>
            <span v-else>否</span>
          </template>
      </el-table-column>

      <el-table-column prop="remark" label="备注">
          <template slot-scope="scope">
              <el-input v-model="scope.row.remark" @change="(value)=>{addRemark(value,scope.row)}"></el-input>
          </template>
      </el-table-column>

      <el-table-column prop="createTime" label="订购时间" show-overflow-tooltip> </el-table-column>
      <el-table-column prop="orderMoney" label="订单金额" show-overflow-tooltip> </el-table-column>
      <el-table-column prop="rebateMoney" label="拼单返现金额" min-width='150' show-overflow-tooltip> </el-table-column>
      <el-table-column label="操作">
              <template slot-scope="scope">
                  <el-button type="primary" class="themed-button" @click="handleTuiKuan(scope.row.orderNumber,scope.row.orderMoney)">标记退款</el-button>
              </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNo"
        :page-sizes="[5, 10, 15, 50,100,1000]"
        :page-size="pageSize"
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
        </el-pagination>
    </div>
    

    <el-dialog title="退款" :visible.sync="dialogRefundVisible" :destroy-on-close='true'>
        <el-form :model="refundForm">
            <el-form-item label="金额" prop="returnMoney">
            <el-input v-model="refundForm.returnMoney" autocomplete="off"  style="width:200px"></el-input>
            </el-form-item>
            <el-form-item label="退款理由" prop="returnReson">
            <el-input type="textarea" v-model="refundForm.returnReson"></el-input>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button class="themed-button" type="info" @click="dialogRefundVisible = false">取 消</el-button>
            <el-button class="themed-button" type="primary" @click="sureRefund" v-preventReClick='1000' :disabled="isDisable">确 定</el-button>
        </div>
        </el-dialog>
    
  </div>
</template>

<script type="text/ecmascript-6">
import { orderRecord,add4remark,refundMoney } from "@/api/cardManagement/cardBillDetail.js";
// import resize from '@/mixins/resize.js'

export default {
  // mixins:[resize],
  data() {
    return {
        pageNo:1,
        pageSize:15,
        total:0,
        tableData:[],
        iccidMark:'',
        dialogRefundVisible:false,
        orderMoney:'',
        refundForm:{
          orderNumber:'',
          returnMoney:'',
          returnReson:'',
        },
        maxTableHeight:null,
        isDisable:false,
    }
  },
  mounted(){
    this._orderRecord()
     if(!!this.$refs.table){
			window.onresize = () => {
				return (() => {
					this.maxTableHeight = window.innerHeight - this.$refs.table.$el.offsetTop - 76 - 40;
				})()
			}
    }
    
  },
  methods: {
      addRemark(val,data){
        console.log(data)
        let params = {}
        params.type = data.type
        params.id = data.id
        params.cardId = this.iccidMark
        params.remark = val
        params.payStatus = 1
        add4remark(params).then((res)=>{
          this._orderRecord()
        })
      },
      handleSizeChange(val){
          this.pageSize = val
          this._orderRecord()
      },
      handleCurrentChange(val){
          this.pageNo = val
          this._orderRecord()
      },
      _orderRecord(){
          let params = this.$route.query
          params.cardId = params.iccidMark
          this.iccidMark = params.iccidMark
          params.pageNo = this.pageNo
          params.pageSize = this.pageSize
          orderRecord(params).then((res) => {
              this.pageNo = res.pageNo
              this.pageSize = res.pageSize
              this.total = res.total
              this.tableData = res.rows
              this.maxTableHeight = window.innerHeight - this.$refs.table.$el.offsetTop - 76 - 40;
          })
      },
      handleTuiKuan(orderNumber,orderMoney){
      this.dialogRefundVisible = true
      this.refundForm.orderNumber = orderNumber
      this.orderMoney = orderMoney
      this.refundForm.returnMoney = ''
      this.refundForm.returnReson = ''
    },

    sureRefund(){
      if(!this.refundForm.returnMoney||!this.refundForm.returnReson){
        this.$alert('请填写退款金额或退款理由', '提示', {
								confirmButtonText: '确定',
							});
							return
      }

      if(parseFloat(this.refundForm.returnMoney) > parseFloat(this.orderMoney)){
        this.$alert('退款金额不能大于订单金额', '提示', {
								confirmButtonText: '确定',
							});
							return
      }
      this.isDisable = true
      refundMoney(this.refundForm).then(res=>{
        this.isDisable = false
            if(res.system_result_key == 0){
                    this.$message({
                        showClose: true,
                        message: '标记退款成功',
                        type: 'success'
                        })
                    this.dialogRefundVisible = false
                    this._orderRecord()
                }
            }).catch(()=>{
              this.isDisable = false
            })
    }
      
  }
};
</script>

<style>
</style>
